import { LocalVideoTrack, RemoteVideoTrack, TwilioError } from 'twilio-video';
import { EventEmitter } from 'events';

declare module 'twilio-video' {
  interface LocalParticipant {
    setBandwidthProfile: (bandwidthProfile: BandwidthProfileOptions) => void;
    publishTrack(track: LocalTrack, options?: { priority: Track.Priority }): Promise<LocalTrackPublication>;
  }

  interface VideoCodecSettings {
    simulcast?: boolean;
  }

  interface LocalTrackPublication {
    setPriority: (priority: Track.Priority) => LocalTrackPublication;
  }

  interface LocalVideoTrack {
    isSwitchedOff: undefined;
    setPriority: undefined;
  }

  interface RemoteVideoTrack {
    isSwitchedOff: boolean;
    setPriority: (priority: Track.Priority | null) => void;
  }

  interface VideoBandwidthProfileOptions {
    trackSwitchOffMode?: 'predicted' | 'detected' | 'disabled';
  }

  function testPreflight(
    subscriberToken: string,
    publisherToken: string,
    options?: { duration?: number }
  ): PreflightTest;
}

declare global {
  interface Window {
    visualViewport?: {
      scale: number;
    };
  }

  interface MediaDevices {
    getDisplayMedia(constraints: MediaStreamConstraints): Promise<MediaStream>;
  }

  interface HTMLMediaElement {
    setSinkId?(sinkId: string): Promise<undefined>;
  }
}

export type Callback = (...args: any[]) => void;

export type ErrorCallback = (error: TwilioError) => void;

export type IVideoTrack = LocalVideoTrack | RemoteVideoTrack;

export type RoomType = 'group' | 'group-small' | 'peer-to-peer' | 'go';

export interface PreflightTestReport {
  isTurnRequired: boolean;
  stats: {
    jitter: {
      min: number;
      max: number;
      average: number;
    };
    rtt?: {
      min: number;
      max: number;
      average: number;
    };
    outgoingBitrate?: {
      min: number;
      max: number;
      average: number;
    };
    incomingBitrate?: {
      min: number;
      max: number;
      average: number;
    };
    packetLoss: {
      min: number;
      max: number;
      average: number;
    };
    networkQuality: {
      min: number;
      max: number;
      average: number;
    };
  };
}

export declare interface PreflightTest extends EventEmitter {
  on(event: 'completed', listener: (report: PreflightTestReport) => void): this;
  on(event: 'failed', listener: (error: Error) => void): this;
  stop: () => void;
}

export interface CustomerDetails {
  name: string;
  phoneNumber?: string;
  email?: string;
  userUid?: string;
  sessionToken?: string;
  sessionUrl?: string;
}

export interface AgentDetails {
  name: string;
  phoneNumber?: string;
  email?: string;
  photoURL?: string;
  userUid?: string;
}

export interface RoomDetails {
  roomName: string;
  sessionId: string;
  customer: CustomerDetails;
}

export interface ApiError extends Error {
  code: number;
}

export type ActivityContext = {
  shareScreenScope?: string;
  sessionUrl?: string;
  error?: ApiError;
  mediaRegion?: string;
  signalingRegion?: string;
};

export enum UserActivityType {
  // Login = 'login',
  // Logout = 'logout',
  // VerifyOTP = 'verifyOTP',
  // OTPSuccess = 'OTPSuccess',
  // OTPFailure = 'OTPFailure',
  CopyInvite = 'copyInvite',
  StartShareScreen = 'startShareScreen',
  EndShareScreen = 'endShareScreen',
  OpenedSessionUrl = 'openedSessionUrl',
  MeetingSessionGenerated = 'meetingSessionGenerated',
  JoinedSession = 'joinedSession',
  StartSession = 'startSession',
  AudioEnabled = 'audioEnabled',
  AudioDisabled = 'audioDisabled',
  VideoEnabled = 'videoEnabled',
  VideoDisabled = 'videoDisabled',
  ClickedEndCall = 'endCall',
  BrowserNotSupported = 'browserNtSupported',
  Error = 'error',
  RoomReconnecting = 'roomReconnecting',
  RoomReconnected = 'roomReconnected',
  ConnectedToRoom = 'connectedToRoom',
}

export interface ApiBaseResponse {
  error?: ApiError;
}

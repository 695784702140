import * as faceapi from 'face-api.js/dist/face-api.js';
import { IVideoTrack } from '../../types';
import { RemoteVideoTrack, Track } from 'twilio-video';
import React, { useEffect, useRef, useState } from 'react';
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoTrackDimensions from '../../hooks/useVideoTrackDimensions/useVideoTrackDimensions';
import randomstring from 'randomstring';
import { styled } from '@material-ui/core/styles';
import { get, isNil } from 'lodash';
import { isMobile } from '../../utils';

const Video = styled('video')({
  width: '100%',
  height: '100%',
  borderRadius: '20px',
});

const VideoContainer = styled('div')({
  width: '100%',
  height: '100%',
  borderRadius: '0px',
  // display: 'grid'
});

const Title = styled('p')({
  color: 'white',
  margin: '30px 0 0 0',
  display: 'flex',
  padding: '0.18em 0.3em',
  position: 'fixed',
  background: 'rgba(0, 0, 0, 0.5)',
  alignItems: 'center',
  top: isMobile ? '85px' : '40px',
  left: '30px',
  width: 'fit-content',
  zIndex: 2,
  borderRadius: '8px',
});

interface SmartVideoTrackProps {
  videoId?: string;
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
  videoClassName?: any;
}

export default function SmartVideoTrack({ track, isLocal, priority, videoClassName, videoId }: SmartVideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!);
  const refDetectionTimeout = useRef<NodeJS.Timeout>(null!);
  const [expression, setExpression] = useState('');
  const [isDetectionModelsLoaded, setIsDetectionModelsLoaded] = useState(false);
  const mediaStreamTrack = useMediaStreamTrack(track);
  const dimensions = useVideoTrackDimensions(track);
  const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);
  const id = videoId || randomstring.generate({ length: 5 });
  const divVideoId = `div-${id}`;
  const actualRemoteTrack = track as RemoteVideoTrack;
  const isRemoteTrack = actualRemoteTrack && actualRemoteTrack.sid;
  const isDoDetections = isRemoteTrack && track && track.kind === 'video';

  // The local video track is mirrored if it is not facing the environment.
  const isFrontFacing = mediaStreamTrack?.getSettings().facingMode !== 'environment';
  const style = {
    transform: isLocal && isFrontFacing ? 'rotateY(180deg)' : '',
    objectFit: isPortrait || track.name.includes('screen') ? ('contain' as const) : ('cover' as const),
  };
  // const canvasStyle = Object.assign(style,
  // { position: 'absolute' });

  const startDetections = () => {
    const video = document.getElementById(id);
    if (isNil(video)) {
      return;
    }
    // console.log('SetInterval', refDetectionTimeout.current);
    if (!isNil(refDetectionTimeout.current)) {
      clearInterval(refDetectionTimeout.current);
    }
    refDetectionTimeout.current = setInterval(async () => {
      if (isNil(video)) {
        return;
      }
      const detections = await faceapi
        .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
        // .withFaceLandmarks()
        .withFaceExpressions();
      const expressions = get(detections, '[0].expressions');
      if (isNil(expressions)) {
        return;
      }
      const expressionsNames = Object.keys(expressions);
      let maxExpression = { name: '', value: 0 };
      expressionsNames.forEach(name => {
        const value = expressions[name];
        if (expressions[name] > maxExpression.value) {
          maxExpression = { name, value };
        }
      });
      if (maxExpression.value > 0.7) {
        setExpression(maxExpression.name);
      } else {
        setExpression('Unrecognized');
      }
      // console.console.log('EXPRESSION', maxExpression.name, maxExpression.value);
      // const resizedDetections = faceapi.resizeResults(detections, displaySize);
      // canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
      // faceapi.draw.drawDetections(canvas, resizedDetections);
      // faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
      // faceapi.draw.drawFaceExpressions(canvas, resizedDetections);
    }, 500);
  };

  const loadModels = async () => {
    // console.log(`loadModels::isDetectionModelsLoaded::${isDetectionModelsLoaded}`);
    if (isDetectionModelsLoaded) {
      startDetections();
      return;
    }
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri('/api/files/detection-models'),
      // faceapi.nets.faceLandmark68Net.loadFromUri('/api/files/detection-models'),
      // faceapi.nets.faceRecognitionNet.loadFromUri('/api/files/detection-models'),
      faceapi.nets.faceExpressionNet.loadFromUri('/api/files/detection-models'),
    ]).then(() => {
      // console.log('Models Loaded', isDetectionModelsLoaded);
      setIsDetectionModelsLoaded(true);
      startDetections();
    });
  };

  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }
    track.attach(el);
    if (isDoDetections) {
      loadModels().then();
    }
    return () => {
      clearInterval(refDetectionTimeout.current);
      track.detach(el);
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  return (
    <VideoContainer id={divVideoId}>
      {expression && <Title>{expression}</Title>}
      <Video id={id} ref={ref} style={style} className={videoClassName} />
    </VideoContainer>
  );
}

import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useAppState } from '../../state';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import { InputLabel, Theme } from '@material-ui/core';
import IntroContainer from '../IntroContainer/IntroContainer';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  googleButton: {
    background: 'white',
    color: 'rgb(0, 94, 166)',
    borderRadius: '4px',
    border: '2px solid rgb(2, 122, 197)',
    margin: '1.8em 0 0.7em',
    textTransform: 'none',
    boxShadow: 'none',
    padding: '0.3em 1em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      background: 'white',
      boxShadow: 'none',
    },
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
  gutterBottom: {
    marginBottom: '1em',
  },
  inputFieldContainer: {
    minHeight: '120px',
    width: '100%',
  },
  submitButtonContainer: {
    padding: '15px 0px',
  },
  inputField: {
    width: '100%',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default function LoginPage() {
  const classes = useStyles();
  const { signIn, user } = useAppState();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [authError, setAuthError] = useState<Error | null>(null);

  const isAuthEnabled = true;

  const login = () => {
    setAuthError(null);
    setIsSigningIn(true);
    signIn?.(email, password)
      .then(() => {
        setIsSigningIn(false);
        history.replace(location?.state?.from || { pathname: '/' });
      })
      .catch(err => {
        setIsSigningIn(false);
        setAuthError(err);
      });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login();
  };

  useEffect(() => {
    if (user || !isAuthEnabled) {
      console.log('LoginPage::REPLACE::HISTORY::/');
      history.replace('/');
      return () => {};
    }
  }, [history, isAuthEnabled, user]);

  // if (!isAuthReady) {
  //   return null;
  // }

  return (
    <IntroContainer>
      <Typography variant="h5" className={classes.gutterBottom}>
        Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container justify="space-between">
          <div className={classes.inputFieldContainer}>
            <InputLabel shrink htmlFor="input-email">
              Email
            </InputLabel>
            <TextField
              id="input-email"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              type="text"
              variant="outlined"
              size="small"
              autoComplete="username"
              className={classes.inputField}
            />
            <InputLabel shrink htmlFor="input-password">
              Password
            </InputLabel>
            <TextField
              id="input-password"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              type="password"
              variant="outlined"
              size="small"
              autoComplete="current-password"
              className={classes.inputField}
            />
            <div>
              {authError && (
                <Typography variant="caption" className={classes.errorMessage}>
                  <ErrorOutlineIcon />
                  {authError.message}
                </Typography>
              )}
            </div>
          </div>
        </Grid>
        <Grid container justify="flex-end" className={classes.submitButtonContainer}>
          <Button
            component={Link}
            to="/forgot/password"
            // justify="flex-start"
            // variant="contained"
            color="primary"
            type="submit"
            // disabled={!email.length || !(password.length > 3) || isSigningIn}
            className={classes.submitButton}
          >
            Reset Password
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!email.length || !(password.length > 3) || isSigningIn}
            className={classes.submitButton}
          >
            {isSigningIn && <CircularProgress size={20} />} Submit
          </Button>
        </Grid>
      </form>
    </IntroContainer>
  );
}

import React from 'react';
import IntroContainer from '../IntroContainer/IntroContainer';
import JoinMeetingByID from '../PreJoinScreens/JoinMeetingByID/JoinMeetingByID';


export default function ConferenceJoinByIDPage() {
  console.log('ConferenceJoinByIDPage');
  return (
    <IntroContainer>
        <>
          <JoinMeetingByID showTitle={true} />
        </>
    </IntroContainer>
  );
}

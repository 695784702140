import React from 'react';
import useTrack from '../../hooks/useTrack/useTrack';
import AudioTrack from '../AudioTrack/AudioTrack';
import VideoTrack from '../VideoTrack/VideoTrack';

import { IVideoTrack } from '../../types';
import {
  AudioTrack as IAudioTrack,
  LocalTrackPublication,
  Participant,
  RemoteTrackPublication,
  Track,
} from 'twilio-video';
import SmartVideoTrack from '../SmartVideoTrack/SmartVideoTrack';
import { useAppState } from '../../state';

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  participant: Participant;
  isLocalParticipant?: boolean;
  videoOnly?: boolean;
  videoPriority?: Track.Priority | null;
  videoClassName?: any;
}

export default function Publication({
  publication,
  participant,
  isLocalParticipant,
  videoOnly,
  videoPriority,
  videoClassName,
}: PublicationProps) {
  const track = useTrack(publication);
  const { user } = useAppState();
  if (!track) return null;
  const renderVideoTrack = () => {
    if (user && !isLocalParticipant) {
      return (
        <SmartVideoTrack
          videoId={participant.identity}
          track={track as IVideoTrack}
          priority={videoPriority}
          isLocal={track.name.includes('camera') && isLocalParticipant}
          videoClassName={videoClassName}
        />
      );
    }
    return (
      <VideoTrack
        videoId={participant.identity}
        track={track as IVideoTrack}
        priority={videoPriority}
        isLocal={track.name.includes('camera') && isLocalParticipant}
        videoClassName={videoClassName}
      />
    );
  };
  switch (track.kind) {
    case 'video':
      return renderVideoTrack();
    case 'audio':
      return videoOnly ? null : <AudioTrack track={track as IAudioTrack} />;
    default:
      return null;
  }
}

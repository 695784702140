import axios from 'axios';
import { LoginResponse, OTPVerifyResponse } from './apis/auth/auth-types';
import { getAnyAuthHeaders, getAuthIntermediateHeaders, getHeaders } from './server/apiHeaders';
import { ActivityContext, ApiBaseResponse } from './types';
import { BrandConfig } from './apis/conference/conference-types';

class Server {
  static async agentLogin(email: string, password: string): Promise<LoginResponse> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          '/api/auth/agent/login',
          { email, password },
          {
            headers: {
              'content-type': 'application/json',
            },
          }
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          // console.error('agentLogin', error);
          reject(error);
        });
    });
  }

  static async logActivity(action: string, sessionId: string, userUid: string, context?: ActivityContext): Promise<LoginResponse> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          '/api/activity/action/l',
          { action, sessionId, userUid, context },
          {
            headers: getAnyAuthHeaders(),
          }
        )
        .then(response => {
          const { token } = response.data;
          resolve({ token });
        })
        .catch(error => {
          // console.error('agentLogin', error);
          reject(error);
        });
    });
  }

  static async verifyOTP(code: string): Promise<OTPVerifyResponse> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          '/api/auth/verify-otp',
          { code },
          {
            headers: getAuthIntermediateHeaders(),
          }
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.error('verifyOTP', error);
          reject(error);
        });
    });
  }

  static async resendOTP(): Promise<ApiBaseResponse> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          '/api/auth/resend-otp',
          {},
          {
            headers: getAuthIntermediateHeaders(),
          }
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.error('resendOTP', error);
          reject(error);
        });
    });
  }

  static async fetchSession(conferenceSessionToken: string): Promise<string> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/conf/session?token=${conferenceSessionToken}`,
          {
            headers: getHeaders(),
          }
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.error('fetchSession', error);
          reject(error);
        });
    });
  }

  static async resetPasswordRequest(email: string): Promise<string> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          '/api/auth/agent/forgot',
          {
            email,
          }
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.error('resetPasswordRequest', error);
          reject(error);
        });
    });
  }

  static async setNewPasswordRequest(perishableToken: string, newPassword): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          '/api/auth/agent/set-new-password',
          {
            perishableToken,
            newPassword
          }
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.error('setNewPasswordRequest', error);
          reject(error);
        });
    });
  }

  static async validateNewPasswordToken(perishableToken: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          '/api/auth/agent/validate-token',
          {
            perishableToken,
          }
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.error('validateNewPasswordToken', error);
          reject(error);
        });
    });
  }

  static async getAgentVideoToken(conferenceSessionId: string, roomName: string): Promise<string> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          '/api/conf/agent/video/token/generate',
          {
            conferenceSessionId,
            roomName,
          },
          {
            headers: getHeaders(),
          }
        )
        .then(response => {
          resolve(response.data.token);
        })
        .catch(error => {
          console.error('getAgentVideoToken', error);
          reject(error);
        });
    });
  }

  static async createLiveConferenceSession(customerDetails): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          '/api/conf/room/create',
          {
            ...customerDetails
          },
          {
            headers: getHeaders(),
          }
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.error('createLiveConferenceSession', error);
          reject(error);
        });
    });
  }

  static async getCustomerVideoToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          '/api/conf/agent/video/token/generate',
          {
            headers: getHeaders(),
          }
        )
        .then(response => {
          resolve(response.data.token);
        })
        .catch(error => {
          console.error('getVideoToken', error);
          reject(error);
        });
    });
  }

  static async getBrandBaseConfig(): Promise<BrandConfig> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          '/api/conf/brand/config/base',
          {
            headers: getHeaders(),
          }
        )
        .then(response => {
          // console.log('getBrandBaseConfig::return', response.data);
          resolve(response.data);
        })
        .catch(error => {
          console.error('getBrandBaseConfig', error);
          reject(error);
        });
    });
  }
}



export { Server };
